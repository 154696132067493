<template>
  <EntitySingle :config="config" :entity.sync="entity" :loaded.sync="entityLoaded">
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <!-- Tab: Store -->
        <b-tab active>
          <template #title>
            <feather-icon icon="ShoppingBagIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Store') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="storeFields"
            allow-autofocus
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard } from 'bootstrap-vue'
import { translationUtils } from '@core/mixins/ui/translations'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'

import storeModule from '../storesStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BTab,
    BTabs,
    BCard,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {},
      entityLoaded: false,
      countryOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'store',
        endpoint: 'store',
        route: 'stores',
        title: {
          single: this.$t('Store_'),
          plural: this.$t('Stores'),
        },
      }
    },
    storeFields() {
      return [
        {
          id: 'country',
          object: true,
          type: 'select',
          label: this.$t('country'),
          options: this.countryOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'perioxh',
          type: 'text',
          label: this.$t('city'),
          required: false,
          colSize: 3,
        },
        {
          id: 'address',
          type: 'text',
          label: this.$t('address'),
          required: true,
          colSize: 3,
        },
        {
          id: 'postalCode',
          type: 'text',
          label: this.$t('postal code'),
          required: false,
          colSize: 3,
        },
        {
          id: 'serviceRadius',
          type: 'area-selection-map',
          colSize: 12,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
  },
}
</script>
